import React from "react";
import {
  CardContainer,
  PaymentCardContainer,
  PaymentInfo,
  PaymentRow,
  PaymentValue,
} from "./PaymenteCard.styles";

const PaymentCard = ({ value, orderName, wasConciliation, tecnospeedId }) => {
  return (
    <CardContainer>
      <PaymentCardContainer>
        <PaymentValue>{value}</PaymentValue>
        <PaymentInfo>
          <PaymentRow>
            <span>Pedido: {orderName}</span>
          </PaymentRow>
          <PaymentRow>
            <span>Avulso: {wasConciliation}</span>
          </PaymentRow>
          <PaymentRow>
            <span>Tecnospeed ID: {tecnospeedId}</span>
          </PaymentRow>
        </PaymentInfo>
      </PaymentCardContainer>
    </CardContainer>
  );
};

export default PaymentCard;

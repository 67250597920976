import {
  startOfDay,
  endOfDay,
  subDays,
  addHours,
  formatISO,
  format,
} from "date-fns";
import { ptBR } from "date-fns/locale";

const today = new Date();
const adjustedToday = addHours(startOfDay(today), 3);

const startOfYesterday = addHours(startOfDay(subDays(today, 1)), 3);
const endOfYesterday = addHours(endOfDay(subDays(today, 1)), 3);

export const datesToFilterPayments = {
  today: {
    label: "Hoje",
    placeholder: format(adjustedToday, "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR,
    }),
    query: [["createdAt", ">=", formatISO(adjustedToday)]],
  },
  yesterday: {
    label: "Ontem",
    placeholder: format(
      addHours(startOfYesterday, 3),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: ptBR,
      },
    ),
    query: [
      ["createdAt", ">=", formatISO(startOfYesterday)],
      ["createdAt", "<=", formatISO(endOfYesterday)],
    ],
  },
};

import { create } from "zustand";

export const usePaymentsStore = create((set) => ({
  payments: [],
  date: "today",
  locations: null,
  selectedLocation: null,
  locationToFilter: null,

  setSelectLocation(selectedLocation) {
    set({ selectedLocation });
  },
  setLocations(locations) {
    set({ locations, selectedLocation: locations[0] });
  },
  setPayments(payments) {
    set({ payments });
  },
  setDate(date) {
    set({ date });
  },

  setLocationToFilter(locationToFilter) {
    set({ locationToFilter });
  },
}));

import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { NsuIdField as NsuIdFieldInput } from "../../../components/NsuIdField/NsuIdField";

export function NsuIdField() {
  const {
    paymentMethod,
    chargedSeparately,
    nsu,
    setNsu,
    automaticConciliation,
  } = useUniquePaymentStore();

  const shouldRenderThisField = () => {
    if (paymentMethod === "LINK") {
      return false;
    }
    if (paymentMethod === "PIX") {
      return false;
    }

    if (chargedSeparately && automaticConciliation) {
      return false;
    }

    return chargedSeparately;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (value) => setNsu(value);

  return <NsuIdFieldInput value={nsu} onChange={handleChange} />;
}

import styled from "styled-components";

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 18px;
  margin-left: 16px;
`;

export const TableContainer = styled.table`
  width: 100%;
  border-radius: 12px;
`;

export const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  border-bottom: 1px solid #ddd;
`;

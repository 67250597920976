import { Page, DataTable } from "@shopify/polaris";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React from "react";
import usePaymentData from "../../../../hook/usePaymentData";
import { TotalSummaryContainer } from "./TotalSummary.styles";

export function extractHour(utcDate) {
  const date = new Date(utcDate);
  const formattedDate = format(date, "HH:mm:ss", { locale: ptBR });
  return formattedDate;
}

function getLabelForMethod(method) {
  switch (method) {
    case "cash":
      return "Dinheiro";
    case "store-credit":
      return "Troca";
    case "link":
      return "Link";
    case "gift-card":
      return "Cartão Presente";
    case "credit":
      return "Crédito";
    case "debit":
      return "Débito";
    case "pix":
      return "Pix";
    default:
      return method || "";
  }
}

export function TotalSummary() {
  const { totalAllPayments } = usePaymentData();

  const rows = (totalAllPayments || []).map((payment) => [
    extractHour(payment.createdAt),
    getLabelForMethod(payment.method) || "",
    `R$${(payment.amountCents / 100).toFixed(2)}`,
    payment.aut || "-",
    payment.wasConciliation ? "Sim" : "Não",
    payment.orderName || "-",
  ]);

  let totalAmountCents = 0;

  for (const payment of totalAllPayments || []) {
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    totalAmountCents += amountCents;
  }

  const totalFormatted = `R$${(totalAmountCents / 100).toFixed(2)}`;

  return (
    <TotalSummaryContainer>
      <Page title="Total">
        <DataTable
          showTotalsInFooter
          truncate
          fixedFirstColumns
          columnContentTypes={[
            "text",
            "numeric",
            "numeric",
            "text",
            "numeric",
            "numeric",
          ]}
          headings={["Hora", "Método", "Valor", "AUT", "Avulso", "Pedido"]}
          rows={rows}
          totals={["", "", totalFormatted, "", "", "", ""]}
          totalsName={{
            singular: "Total",
          }}
        />
      </Page>
    </TotalSummaryContainer>
  );
}

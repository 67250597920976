import {
  Card,
  Page,
  Layout,
  FormLayout,
  Heading,
  Button,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ErrorSituation from "../../component/errorSituation/ErrorSituation.jsx";
import useDevice from "../../hook/useDevice.js";
import useProducer from "../../hook/useProducer.js";

const PaymentTitle = styled.div`
  margin-top: 6px;
  font-size: 26px;
  font-weight: bold;
`;

const Options = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
`;
const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  padding: 17px;
  border-bottom: 1px solid #d9d9d9;
`;

const OptionCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 150%;
`;
const Quantity = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 0 15px;
`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProductQuantity = styled.div`
  color: #555;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ProductName = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #222;
`;

function Counter({ quantity, incrementQuantity, maxQuantity }) {
  return (
    <OptionCounter>
      <Button onClick={() => incrementQuantity(-1)} disabled={quantity === 0}>
        -
      </Button>
      <Quantity>{quantity}</Quantity>
      <Button
        onClick={() => incrementQuantity(1)}
        disabled={quantity === maxQuantity}
      >
        +
      </Button>
    </OptionCounter>
  );
}

export default function CreateReturnPage({
  shopifyOrder,
  setLoadingReturn,
  loadingReturn,
}) {
  const [returnItems, setReturnItems] = useState([]);
  const producer = useProducer();

  const device = useDevice();
  const currentLocationId = `${device?.pos?.location?.id}`;
  const staffId = `${device?.pos?.user?.id}`;

  useEffect(() => {
    if (shopifyOrder.items) {
      setReturnItems(
        shopifyOrder.items.map(({ sku, name, quantity }) => {
          return {
            sku,
            name,
            currentQuantity: 0,
            maxQuantity: quantity,
          };
        }),
      );
    }
  }, [shopifyOrder]);

  const incrementQuantity = (sku) => (increment) => {
    const itemFoundIndex = returnItems.findIndex((item) => {
      return item.sku === sku;
    });
    if (itemFoundIndex < 0) {
      return;
    }
    const newValue = returnItems[itemFoundIndex].currentQuantity + increment;
    const maxValue = returnItems[itemFoundIndex].maxQuantity;
    if (newValue < 0 || newValue > maxValue) {
      return;
    }
    setReturnItems([
      ...returnItems.slice(0, itemFoundIndex),
      {
        ...returnItems[itemFoundIndex],
        currentQuantity: newValue,
      },
      ...returnItems.slice(itemFoundIndex + 1),
    ]);
  };

  const disableReturn = () => {
    const itemsQuantity = returnItems.reduce(
      (acc, curr) => acc + curr.currentQuantity,
      0,
    );
    return itemsQuantity === 0;
  };

  const nfNotCreated = shopifyOrder.errorSituation.isSaleNfcNotCreated;
  const nfCancelled = shopifyOrder.errorSituation.isSaleNfCancelled;
  const nfReturned = shopifyOrder.errorSituation.isNfeReturned;
  const isNotReturnable = shopifyOrder?.isReturnable === false;

  const navigate = useNavigate();

  const redirectGenerateNf = () => {
    navigate("/generate-nfce");
  };

  const GenerateNf = () => {
    return (
      <Button primary onClick={redirectGenerateNf}>
        Gerar NF
      </Button>
    );
  };

  const conditionOfError = {
    nfNotCreated,
    nfCancelled,
    nfReturned,
    isNotReturnable,
  };

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card
            sectioned
            title={
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Heading>Pedido {shopifyOrder.name}</Heading>
                </div>
                <PaymentTitle>Devolução</PaymentTitle>
              </div>
            }
            primaryFooterAction={{
              content: "Devolver",
              onAction: () => {
                const sourceLocationId = shopifyOrder?.posLocationId || null;
                producer.produce(
                  producer.createEvent({
                    type: "processReturn",
                    payload: {
                      returnedItems: returnItems.reduce((acc, item) => {
                        if (item.currentQuantity === 0) {
                          return acc;
                        }
                        return [
                          ...acc,
                          {
                            sku: item.sku,
                            quantity: item.currentQuantity,
                            name: item.name,
                          },
                        ];
                      }, []),
                      sourceLocationId,
                      returnId: `${shopifyOrder.id}`,
                      toLocationId: null,
                      shopifyOrderId: `${shopifyOrder.id}`,
                      companyShop: shopifyOrder.companyShop,
                      staffId,
                      currentLocationId,
                    },
                  }),
                );
                setLoadingReturn(true);
              },
              loading: loadingReturn,
              disabled: !device?.pos || disableReturn() || loadingReturn,
            }}
          >
            <ErrorSituation
              conditions={conditionOfError}
              path={"/one-button-page"}
              cases={{
                nfNotCreated: {
                  title: "Nota Fiscal Não Gerada",
                  errorMessage:
                    "A nota fiscal de venda para este pedido ainda não foi gerada. Se desejar prosseguir com a devolução, por favor gere a nota fiscal primeiro.",
                },
                nfCancelled: {
                  title: "Não é possível realizar a devolução",
                  errorMessage:
                    "A nota fiscal de venda para este pedido já foi cancelada. Não há itens para serem devolvidos. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
                },
                nfReturned: {
                  title: "Não há itens para devolução",
                  errorMessage:
                    "Todos os itens deste pedido já foram devolvidos. Não há possibilidade de realizar novas devoluções.",
                },
                isNotReturnable: {
                  title: "Não é possivel devolver esse pedido",
                  errorMessage:
                    "O pedido está fora do prazo previsto para a devolução.",
                },
              }}
              ExtraActions={nfNotCreated && GenerateNf}
            >
              <Card.Section>
                <FormLayout>
                  <Title>Produtos</Title>

                  <Options>
                    {returnItems &&
                      returnItems.map(
                        ({ sku, name, maxQuantity, currentQuantity }) => (
                          <Option key={sku}>
                            <ProductInfo>
                              <ProductName>{name}</ProductName>
                              <ProductQuantity>
                                Quantidade: {maxQuantity}
                              </ProductQuantity>
                            </ProductInfo>
                            <Counter
                              quantity={currentQuantity}
                              maxQuantity={maxQuantity}
                              incrementQuantity={incrementQuantity(sku)}
                            />
                          </Option>
                        ),
                      )}
                  </Options>
                </FormLayout>
              </Card.Section>
            </ErrorSituation>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

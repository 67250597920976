import { Page, Layout, Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame";
import { TabPanel } from "../../component/Tabs/TabPanel.jsx";
import useFirestoreQuery from "../../hook/useFirestoreQuery";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import { usePaymentsStore } from "../../store/usePaymentsStore";
import {
  ANALYTICS_PAYMENT_TABS,
  usePaymentsSummaryTabsStore,
} from "../../store/usePaymentsSummaryTabsStore.js";
import {
  IgluAnalyticsButtonContainer,
  Title,
} from "../IgluAnalytics/IgluAnalytics.styles.js";
import { CashSummary } from "./components/CashSummary/CashSummary.jsx";
import { CreditSummary } from "./components/CreditSummary/CreditSummary.jsx";
import { datesToFilterPayments } from "./components/datesToFilterPayments/datesToFilterPayments.jsx";
import { DebitSummary } from "./components/DebitSummary/DebitSummary.jsx";
import { FilterDatePayments } from "./components/FilterDatePayments/FilterDatePayments.jsx";
import { LinkSummary } from "./components/LinkSummary/LinkSummary.jsx";
import LocationFilter from "./components/LocationFilter/LocationFilter.jsx";
import { PixSummary } from "./components/PixSummary/PixSummary.jsx";
import { StoreCreditSummary } from "./components/StoreCreditSummary/StoreCreditSummary.jsx";
import { SummaryTable } from "./components/SummaryTable/SummaryTable.jsx";
import { TotalSummary } from "./components/TotalSummary/TotalSummary.jsx";

export default function Payments() {
  useHideLoadingIndicator();
  const companyShop = useShopifyShopUrl();

  const { paymentsSummary, setPaymentsSummary } = usePaymentsSummaryTabsStore();
  const {
    setLocations,
    selectedLocation,
    setPayments,
    date,
    locationToFilter,
  } = usePaymentsStore();

  const locationQuery = useFirestoreQuery("posLocation", [
    "companyShop",
    "==",
    companyShop,
  ]);

  const paymentQueryOptions = [
    ["companyShop", "==", companyShop],
    ["status", "==", "settled"],
    ["createdAt", ">=", date],
  ];

  const [filterPayment, setFilterPayment] = useState([
    ["companyShop", "==", companyShop],
    ["status", "==", "settled"],
    ["posLocationId", "==", locationToFilter],
    ...datesToFilterPayments[date].query,
  ]);

  const paymentsData = useFirestoreQuery("payment", ...filterPayment);

  useEffect(() => {
    setPayments(paymentsData?.data || []);
  }, [paymentsData?.data]);

  useEffect(() => {
    {
      setFilterPayment([
        ["companyShop", "==", companyShop],
        ["status", "==", "settled"],
        ["posLocationId", "==", locationToFilter],
        ...datesToFilterPayments[date].query,
      ]);
    }
  }, [selectedLocation?.value, date, locationToFilter]);

  const paymentsQuery = useFirestoreQuery(
    "posLocation",
    ...(selectedLocation?.value
      ? [
          ...paymentQueryOptions,
          ["posLocationId", "==", selectedLocation?.value],
        ]
      : paymentQueryOptions),
  );

  const goBack = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value);
  };

  useEffect(() => {
    if (paymentsQuery?.data) {
      setPayments(
        paymentsQuery?.data.map(({ method, createdAt, amountInCents }) => ({
          method,
          createdAt,
          amountInCents,
        })),
      );
    }
  }, [paymentsQuery?.data]);

  useEffect(() => {
    if (locationQuery?.data) {
      setLocations(
        locationQuery?.data.map(({ name, id }) => ({
          label: name,
          placeholder: name,
          value: id,
        })),
      );
    }
  }, [locationQuery?.data]);

  if (locationQuery.isLoading || paymentsQuery.isLoading) {
    return (
      <Page>
        <LoadingFrame />
      </Page>
    );
  }

  const showButton = () => {
    if (paymentsSummary === ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Page>
        <IgluAnalyticsButtonContainer>
          {showButton() && (
            <Button plain large onClick={goBack}>
              Voltar
            </Button>
          )}
        </IgluAnalyticsButtonContainer>

        <Layout>
          <Layout.Section>
            <Title>Pagamentos</Title>

            <LocationFilter />
            <FilterDatePayments />

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value}
            >
              <SummaryTable />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CASH.value}
            >
              <CashSummary />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CREDIT.value}
            >
              <CreditSummary />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_DEBIT.value}
            >
              <DebitSummary />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_PIX.value}
            >
              <PixSummary />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_STORE_CREDIT.value}
            >
              <StoreCreditSummary />
            </TabPanel>

            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_LINK.value}
            >
              <LinkSummary />
            </TabPanel>
            <TabPanel
              value={paymentsSummary}
              index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_TOTAL.value}
            >
              <TotalSummary />
            </TabPanel>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}

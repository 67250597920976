import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../../component/modal/Confirmation.jsx";
import { RadioButtonCard } from "../../../../component/RadioButtonCard/RadioButtonCard.jsx";
import { SelectButton } from "../../../../component/SelectButton/SelectButton.jsx";
import { usePaymentsStore } from "../../../../store/usePaymentsStore.js";

export default function LocationFilter() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { selectedLocation, locations, setLocationToFilter } =
    usePaymentsStore();

  const [selectedOption, setSelectedOption] = useState(selectedLocation.value);

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const [selectedLocationLabel, setSelectedLocationLabel] = useState(
    locations.find((option) => option.value === selectedLocation.value)?.label,
  );

  const updateSelectedLocationLabel = () => {
    setSelectedLocationLabel(
      locations.find((option) => option.value === selectedOption)?.label,
    );
  };

  useEffect(() => {
    setSelectedOption(selectedLocation);
    setLocationToFilter(selectedLocation?.value);
  }, [selectedLocation, setLocationToFilter]);

  const handleConfirmation = () => {
    setSelectedOption(selectedOption);
    setLocationToFilter(selectedOption);
    updateSelectedLocationLabel();
    closeConfirmationModal();
  };

  return (
    <>
      <SelectButton
        text={selectedLocationLabel}
        onClick={openConfirmationModal}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        title="Filtrar por location"
        prompt={locations.map((option) => (
          <RadioButtonCard
            key={option.id}
            label={option.label}
            value={option.value}
            onChange={() => handleOptionChange(option.value)}
            checked={option.value === selectedOption}
          />
        ))}
        confirmText="Confirmar"
        cancelText="Cancelar"
        onConfirm={handleConfirmation}
        onCancel={() => {
          closeConfirmationModal();
        }}
      />
    </>
  );
}

import { useState } from "react";
import ConfirmationModal from "../../../../component/modal/Confirmation";
import { RadioButtonCard } from "../../../../component/RadioButtonCard/RadioButtonCard";
import { SelectButton } from "../../../../component/SelectButton/SelectButton";
import { usePaymentsStore } from "../../../../store/usePaymentsStore";
import { datesToFilterPayments } from "../datesToFilterPayments/datesToFilterPayments";

export function FilterDatePayments() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { date, setDate } = usePaymentsStore();

  const dateOptions = Object.entries(datesToFilterPayments).map(
    ([key, data], index) => ({
      value: key,
      label: data.label,
      placeholder: data.placeholder,
      id: index,
    }),
  );

  const [selectedOption, setSelectedOption] = useState(date);

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleConfirmation = () => {
    setDate(selectedOption);
    closeConfirmationModal();
  };

  return (
    <>
      <SelectButton
        text={
          dateOptions.find((option) => option.value === date)?.label ||
          dateOptions[0].label
        }
        onClick={openConfirmationModal}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        title="Filtrar por data"
        prompt={dateOptions.map((option) => (
          <RadioButtonCard
            key={option.id}
            label={option.label}
            helpText={option.placeholder}
            value={option.value}
            onChange={() => handleOptionChange(option.value)}
            checked={option.value === selectedOption}
          />
        ))}
        confirmText="Confirmar"
        cancelText="Cancelar"
        onConfirm={handleConfirmation}
        onCancel={() => {
          closeConfirmationModal();
        }}
      />
    </>
  );
}

import { Button } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";

import usePaymentData from "../../../../hook/usePaymentData";
import {
  ANALYTICS_PAYMENT_TABS,
  usePaymentsSummaryTabsStore,
} from "../../../../store/usePaymentsSummaryTabsStore.js";

import {
  TableContainer,
  TableCell,
  TableTitle,
  TableRow,
} from "./SummaryTable.styles.js";

export function SummaryTable() {
  const { setPaymentsSummary } = usePaymentsSummaryTabsStore();
  const { totalPaymentsMethod, occurrences, totalAllPayments } =
    usePaymentData();

  function formatCurrency(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  const totalAmount = Object.values(totalPaymentsMethod).reduce(
    (total, value) => total + value,
    0,
  );

  const handleRowClick = (onClickFunction) => {
    onClickFunction();
  };

  const goToCashTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CASH.value);
  };

  const goToCreditTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CREDIT.value);
  };

  const goToDebitTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_DEBIT.value);
  };

  const goToPixTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_PIX.value);
  };

  const goToStoreCreditTab = () => {
    setPaymentsSummary(
      ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_STORE_CREDIT.value,
    );
  };

  const goToPaymentLinkTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_LINK.value);
  };

  const goToTotalTab = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_TOTAL.value);
  };

  const SALES_SUMMARY = [
    {
      label: "Crédito",
      amount: formatCurrency(totalPaymentsMethod.credit),
      onClick: goToCreditTab,
      occurrences: occurrences?.credit,
    },
    {
      label: "Débito",
      amount: formatCurrency(totalPaymentsMethod.debit),
      onClick: goToDebitTab,
      occurrences: occurrences?.debit,
    },
    {
      label: "Dinheiro",
      amount: formatCurrency(totalPaymentsMethod.cash),
      onClick: goToCashTab,
      occurrences: occurrences?.cash,
    },
    {
      label: "Pix",
      amount: formatCurrency(totalPaymentsMethod.pix),
      onClick: goToPixTab,
      occurrences: occurrences?.pix,
    },
    {
      label: "Link de Pagamento",
      amount: formatCurrency(totalPaymentsMethod.link),
      onClick: goToPaymentLinkTab,
      occurrences: occurrences?.link,
    },
    {
      label: "Cartão Presente",
      amount: formatCurrency(totalPaymentsMethod["gift-card"]),
      onClick: goToStoreCreditTab,
      occurrences: occurrences?.["gift-card"],
    },
    {
      label: "Troca - Créditos",
      amount: formatCurrency(totalPaymentsMethod["store-credit"]),
      onClick: goToStoreCreditTab,
      occurrences: occurrences?.["store-credit"],
    },
    {
      label: "Total",
      amount: formatCurrency(totalAmount),
      onClick: goToTotalTab,
      occurrences: totalAllPayments?.length,
    },
  ];

  return (
    <TableContainer>
      <TableTitle>Resumo</TableTitle>
      {SALES_SUMMARY.map(({ label, amount, onClick, occurrences }, index) => (
        <TableRow key={index} onClick={() => handleRowClick(onClick)}>
          <TableCell>{label}</TableCell>
          <TableCell>{amount}</TableCell>
          <TableCell>{occurrences}</TableCell>
          <TableCell>
            <Button plain icon={ChevronRightMinor}></Button>
          </TableCell>
        </TableRow>
      ))}
    </TableContainer>
  );
}

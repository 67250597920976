import { Button, Icon } from "@shopify/polaris";
import { CircleTickMinor } from "@shopify/polaris-icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Message from "../../../../component/message/Message";
import SendEmailModal from "../../../../component/modal/SendEmailModal";
import NfProgressStatus from "../../../../component/nfProgressStatus/NfProgressStatus";
import PageSkeleton from "../../../../component/skeletonLoading/PageSkeleton";
import { useNavigate } from "../../../../hook/useNavigate.js";
import backend from "../../../../service/backend.js";
import { useFiscalInvoiceStore } from "../../../../store/useFiscalInvoiceStore";
import { useOrderStore } from "../../../../store/useOrderStore";

import HtmlToImageConverter from "./HtmlToImageConverter/HtmlToImageConverter";
import {
  ActionButtons,
  Container,
  ContainerSkeleton,
  NfcePanelButtonContainer,
  NfcePanelTitle,
  ProgressStatusIcon,
  RegisterEmail,
  SubtitlePayment,
  TitleFinishedPayment,
} from "./NfcePanel.styles";

export function NfcePanel({ configuration, location, paymentList }) {
  const navigate = useNavigate();
  const [sendEmailModalIsOpen, setSendEmailModalIsOpen] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const { exchangeVoucher: exchangeVoucherFlag } = useFlags();

  const openSendEmailModal = () => setSendEmailModalIsOpen(true);
  const closeSendEmailModal = () => setSendEmailModalIsOpen(false);

  const { fiscalInvoiceStatus, fiscalInvoiceId, fiscalInvoiceVincoError } =
    useFiscalInvoiceStore();
  const { shopifyOrderId, companyShop, email, isForShipping } = useOrderStore();
  const renderEmail = email && email.length > 0;

  const sendEmail = useCallback(
    async (receiverEmail) => {
      setIsSendingEmail(true);
      await backend.executeProcess({
        type: "fiscal/sendNfceEmail",
        input: {
          shopifyOrderId,
          companyShop,
          nfceId: fiscalInvoiceId,
          receiverEmail,
          uuid: uuidv4(),
        },
      });
      setIsSendingEmail(false);
      closeSendEmailModal();
    },
    [fiscalInvoiceId, shopifyOrderId, companyShop],
  );

  const nfceDone = fiscalInvoiceStatus === "authorized";
  const nfceInProgress = !["authorized", "error"].includes(fiscalInvoiceStatus);
  const importProductError = fiscalInvoiceVincoError === "ERP PRODUCT ERROR";
  const ncmError = fiscalInvoiceVincoError === "NCM ERROR";
  const skuError = fiscalInvoiceVincoError === "SKU ERROR";
  const nfceError = fiscalInvoiceStatus === "error";

  const handleToOneButton = () => {
    navigate("/one-button-page");
  };

  const paymentService =
    location?.payment?.card?.service ?? configuration?.payment?.card?.service;
  const tefPaymentList =
    paymentService !== "softwareexpress"
      ? []
      : paymentList.filter((payment) =>
          ["credit", "debit"].includes(payment.method),
        );
  const hasTefPayment = tefPaymentList.length > 0;
  const s = tefPaymentList.length === 1 ? "" : "s";

  const printerService =
    location?.printer?.service ?? configuration?.printer?.service;
  if (!["bluetooth", "wifi"].includes(printerService)) {
    throw new Error(
      `Bad configuration \`printer.service\`: \`${printerService}\``,
    );
  }
  const hasWifiPrinter = printerService === "wifi";

  const printSaleDocument = () => {
    if (printerService === "bluetooth") {
      window.open(
        `${process.env.HOST}/api/nfce/${encodeURIComponent(
          fiscalInvoiceId,
        )}/${encodeURIComponent(companyShop)}`,
        "_blank",
      );
    } else if (printerService === "wifi") {
      // For a sale with at least one TEF payment,
      // we print the concatenation of the payment-receipts with the sale-nfce.
      // For a sale without any TEF payment, we print only the sale-nfce.
      window.open(
        `iglu://print-sale-document/?${new URLSearchParams({
          borg:
            process.env.NODE_ENV === "development"
              ? window.location.origin
              : window.location.origin.replace(/app-/, ""),
          shopifyShopHost: companyShop,
          shopifyOrderId,
        }).toString()}`,
        "_blank",
      );
    } else {
      throw new Error("Unreachable");
    }
  };

  const printPaymentReceiptList = () => {
    if (printerService !== "wifi") throw new Error("unreachable");

    const tefPaymentIdList = tefPaymentList.map((payment) => payment.id);

    const urlSearchParamsString = new URLSearchParams({
      borg:
        process.env.NODE_ENV === "development"
          ? window.location.origin
          : window.location.origin.replace(/app-/, ""),
      ssho: companyShop,
      soid: shopifyOrderId,
      pwip: location?.printer?.wifi?.ip,
      tpid: tefPaymentIdList.join(","),
    }).toString();

    window.open(`iglu://print-tef-receipt/?${urlSearchParamsString}`, "_blank");
  };

  const printExchangeVoucher = () => {
    if (printerService !== "wifi") throw new Error("unreachable");

    const urlSearchParamsString = new URLSearchParams({
      borg:
        process.env.NODE_ENV === "development"
          ? window.location.origin
          : window.location.origin.replace(/app-/, ""),
      pwip: location?.printer?.wifi?.ip,
      ssdo: companyShop, // shopifyShopDomain
      soid: shopifyOrderId,
    }).toString();

    window.open(
      `iglu://print-exchange-voucher/?${urlSearchParamsString}`,
      "_blank",
    );
  };

  if (isForShipping) {
    return (
      <Container>
        <div>
          <NfcePanelTitle>Pagamento</NfcePanelTitle>
          <ProgressStatusIcon>
            <Icon source={CircleTickMinor} color="success" />
          </ProgressStatusIcon>
          <TitleFinishedPayment>Pagamento Finalizado</TitleFinishedPayment>
          <SubtitlePayment>
            Seu pagamento foi finalizado, pode fechar a página.
          </SubtitlePayment>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div>
        <SendEmailModal
          isOpen={sendEmailModalIsOpen}
          onClose={closeSendEmailModal}
          onSubmit={({ customerEmail }) => sendEmail(customerEmail)}
          initialCustomerEmail={email ?? ""}
        />
        <NfcePanelTitle>Nota Fiscal</NfcePanelTitle>
      </div>

      <NfProgressStatus
        nfceInProgress={nfceInProgress}
        nfceDone={nfceDone}
        ncmError={ncmError}
        importProductError={importProductError}
        skuError={skuError}
        nfceError={nfceError}
      />

      {nfceInProgress && (
        <NfcePanelButtonContainer>
          {hasTefPayment && hasWifiPrinter && (
            <Button onClick={printPaymentReceiptList}>
              Imprimir Comprovante{s} de Pagamento
            </Button>
          )}
          {exchangeVoucherFlag && hasWifiPrinter && (
            <Button onClick={printExchangeVoucher}>
              Imprimir Etiqueta de Troca
            </Button>
          )}
          <Button onClick={handleToOneButton}>Fechar</Button>
          <ContainerSkeleton>
            <PageSkeleton />
          </ContainerSkeleton>
        </NfcePanelButtonContainer>
      )}
      {nfceDone && (
        <NfcePanelButtonContainer>
          <ActionButtons>
            {hasTefPayment && hasWifiPrinter && (
              <Button onClick={printPaymentReceiptList}>
                Imprimir Comprovante{s} de Pagamento
              </Button>
            )}
            {exchangeVoucherFlag && hasWifiPrinter && (
              <Button onClick={printExchangeVoucher}>
                Imprimir Etiqueta de Troca
              </Button>
            )}
            <Button size="large" fullWidth onClick={printSaleDocument}>
              Imprimir NFC-e
            </Button>
            <Button
              size="large"
              fullWidth
              primary
              onClick={openSendEmailModal}
              loading={isSendingEmail}
            >
              {renderEmail
                ? "Re-Enviar NFC-e por email"
                : "Enviar NFC-e por email"}
            </Button>
            {renderEmail ? (
              <Message />
            ) : (
              <RegisterEmail>
                Não tem e-mail cadastrado,{" "}
                <Button plain onClick={openSendEmailModal}>
                  toque aqui
                </Button>{" "}
                e cadastre um e-mail.
              </RegisterEmail>
            )}
          </ActionButtons>

          <HtmlToImageConverter />
        </NfcePanelButtonContainer>
      )}
    </Container>
  );
}
